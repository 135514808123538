import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["modal", "modalClose"];

  close(event) {
    document.body.classList.remove('modal-open')
    this.modalTarget.classList.remove('show')
    location.reload(true);
  }

  closeWithoutReload(event) {
    document.body.classList.remove('modal-open')
    this.modalTarget.classList.remove('show')
  }
}
