import { Controller } from "stimulus"
import Rails from 'rails-ujs'

export default class extends Controller {
  static targets = [
    "tagsInput", 'tagsTarget'
  ]

  connect() {
    if (this.hasTagsTargetTarget) {
      $(this.tagsTargetTarget).tagsinput()
    }
  }

  onTagsEnter(event) {
    let keycode = (event.keyCode ? event.keyCode : event.which);
    if (keycode == '13') {
      event.preventDefault()
      event.stopImmediatePropagation()
      let input = event.target
      let target = document.querySelector('.' + input.getAttribute('data-tagstarget'))
      $(target).tagsinput('add', input.value)
      input.value = ''
      return false;
    }
  }

  preventTyping(event) {
    event.preventDefault()
    event.stopImmediatePropagation()
    return false
  }
}
