import { Controller } from "stimulus"
import { showModal, selectAllCheckboxes, errorsList, showToastr, bootboxConfirm } from "../helpers/utils"
import { getJsonRequest } from "../helpers/request"
import Rails from 'rails-ujs'

export default class extends Controller {
  static targets = [
    "selectAll", "linkIndexCheckbox", "editSelected",
    "noMultipleEditTemplate", "newLinkBtn", "newLinkFormTemplate", "selectedIds",
    "activateSelected", "deactivateSelected", "deleteSelected"
  ]

  initialize() {}

  connect() {}

  selectAllRecords(event) {
    let target = event.target;
    selectAllCheckboxes(this.linkIndexCheckboxTargets, target.checked)
    this.enableOrDisableLinks()
  }

  selectRecord(event) {
    this.enableOrDisableLinks()
  }

  enableOrDisableLinks() {
    if (this.linkIndexCheckboxTargets.some(chkbox => chkbox.checked)) {
      this.deleteSelectedTarget.classList.remove('disabled-link')
      this.editSelectedTarget.classList.remove('disabled-link')
    } else {
      this.deleteSelectedTarget.classList.add('disabled-link')
      this.editSelectedTarget.classList.add('disabled-link')
    }
  }

  /* BULK ACTION */

  fetchSelectedIds() {
    let that = this
    let selectedIds = []
    let selectedCount = 0
    that.linkIndexCheckboxTargets.forEach((el, i) => {
      if (el.checked) {
        selectedIds.push(el.getAttribute('value'))
        selectedCount = selectedCount + 1
      }
    })
    if (selectedCount >= 1) {
      that.selectedIdsTarget.value = JSON.stringify(selectedIds)
    }
    // } else {
    //   toastr.error("Please select at least one record")
    // }
    return selectedIds
  }

  showBootboxConfirm(confirmationText, url) {
    let that = this
    let selectedIds = that.fetchSelectedIds()
    if (selectedIds && selectedIds.length > 0) {
      bootboxConfirm(confirmationText, yesText, noText, function(result) {
        if (result) {
          that.selectedIdsTarget.parentNode.setAttribute('action', url)
          Rails.fire(that.selectedIdsTarget.parentNode, 'submit');
        }
      })
    }
  }

  editLink() {
    let url = this.activateSelectedTarget.dataset.url
    let selectedIds = this.fetchSelectedIds()
    let selectedCount = selectedIds.length
    if (selectedCount == 0) { return }
    if (selectedCount == 1) {
      this.renderModal(linksBasePath + '/' + selectedIds[0] + '/edit')
    } else {
      toastr.error(noMultipleEditText)
    }
  }

  activateLink() {
    let url = this.activateSelectedTarget.dataset.url
    this.showBootboxConfirm(activateConfirmationText, url)
  }

  deactivateLink() {
    let url = this.deactivateSelectedTarget.dataset.url
    this.showBootboxConfirm(deactivateConfirmationText, url)
  }

  deleteLink() {
    let url = this.deleteSelectedTarget.dataset.url
    this.showBootboxConfirm(deleteConfirmationText, url)
  }

  batchActionSuccess(event) {
    let [data, status, xhr] = event.detail;
    if (data.success) {
      window.location.reload()
    } else {
      this.batchActionError(event)
    }
  }

  batchActionError(event) {
    let [data, status, xhr] = event.detail;
    let el = event.target.querySelector('.error-list-container')
    if (!data.success) {
      if (el) {
        el.innerHTML = errorsList(data.errors)
      } else {
        showToastr(errorTitle, errorsList(data.errors), 'error')
      }
    }
  }

  /* BULK ACTION */

  newLink() {
    this.renderModal(linksBasePath + '/new')
  }

  renderModal(url) {
    getJsonRequest(url).then(response => {
      if (response.success) {
        showModal(response.html, "atalisModal", "atalisModal")
      }
    })
  }

  saveLinkSuccess(event) {
    let [data, status, xhr] = event.detail;
    if (data.success) {
      showToastr(successTitle, data.message, 'success')
    } else {
      this.saveLinkError(event)
    }
  }

  saveLinkError(event) {
    let [data, status, xhr] = event.detail;
    if (!data.success) {
      event.target.querySelector('.error-list-container').innerHTML = errorsList(data.errors)
    }
  }
}
