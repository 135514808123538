import { Controller } from "stimulus"
import { showToastr } from "../helpers/utils"
import { postJsonRequest } from "../helpers/request"

export default class extends Controller {
  static targets = ["universityDropdown"]

  connect() {}

  assign(event) {
    let target = event.target
    let assign = target.getAttribute('data-isassigned') == 'true' ? 0 : 1
    let isForm = target.getAttribute('data-isform')
    if (isForm != 'true') {
      let params = {
        assignable: {
          university_id: target.getAttribute('data-universityid'),
          resource_id: target.getAttribute('data-resourceid'),
          resource_type: target.getAttribute('data-resourcetype')
        },
        assign: assign
      }
      postJsonRequest(
        '/admin/universities/assign',
        params
      ).then(response => {
        if (response.success) {
          this.markAssigned(target, assign)
          showToastr(successTitle, response.message, 'success')
        }
      })
    } else {
      this.markAssigned(target, assign)
      let $select = this.universityDropdownTarget
      // Select options of the dropdown
      for (let i = 0, l = $select.options.length, o; i < l; i++) {
        o = $select.options[i]
        if (o.value == target.getAttribute('data-universityid') && assign == 1) {
          o.selected = true
        } else if (o.value == target.getAttribute('data-universityid') && assign == 0) {
          o.selected = false
        } else {
          // Do nothing
        }
      }
    }
  }

  markAssigned(target, assign) {
    target.classList.toggle('grey')
    if (assign == 1) {
      target.setAttribute('data-isassigned', 'true')
    } else {
      target.setAttribute('data-isassigned', 'false')
    }
  }
}
