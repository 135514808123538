// Td Popup menu
export function positionMenu(e, identifier, relatedIdentifier) {
  var menu = $(identifier);
  let clickCoords = getMenuPosition(e);
  let clickCoordsX = clickCoords.x;
  let clickCoordsY = clickCoords.y;

  let menuWidth = menu.offsetWidth + 4;
  let menuHeight = menu.offsetHeight + 4;

  let windowWidth = window.innerWidth;
  let windowHeight = window.innerHeight;

  let leftValue;
  let topValue;

  if ((windowWidth - clickCoordsX) < menuWidth) {
    leftValue = windowWidth - menuWidth + 'px';
  } else {
    leftValue = clickCoordsX + 'px';
  }

  let relatedHeight = $(relatedIdentifier).length == 0 ? 0 : $(relatedIdentifier).offset().top;
  if ((windowHeight - clickCoordsY) < menuHeight) {
    topValue = windowHeight - menuHeight - relatedHeight + 'px';
  } else {
    topValue = clickCoordsY - relatedHeight + 'px';
  }

  menu.css({
    position: 'absolute',
    left: leftValue,
    top: topValue
  })
}

function getMenuPosition(e) {
  let posx = 0;
  let posy = 0;

  if (!e) var e = window.event;

  if (e.pageX || e.pageY) {
    posx = e.pageX;
    posy = e.pageY;
  } else if (e.clientX || e.clientY) {
    posx = e.clientX + document.body.scrollLeft +
      document.documentElement.scrollLeft;
    posy = e.clientY + document.body.scrollTop +
      document.documentElement.scrollTop;
  }

  return {
    x: posx,
    y: posy
  }
}



// TO BE IMEPLEMENTED IN THID WAY //

// export function positionMenu(event, identifier, relatedIdentifier) {
//   if (!identifier) var identifier = '.tdMenuPopup';
//   if (!relatedIdentifier) var relatedIdentifier = '#tabs';

//   let menu = $(identifier);
//   let menuWidth = $(menu)[0].offsetWidth;
//   let menuHeight = $(menu)[0].offsetHeight;
//   let clickCoords = getMenuPosition(event);

//   menu.css({
//     position: 'absolute',
//     left: leftValue(clickCoords.x, menuWidth),
//     top: topValue(clickCoords.y, menuHeight, relatedIdentifier)
//   })
// }


// function leftValue(clickCoordsX, menuWidth) {
//   let windowWidth = window.innerWidth;

//   if ( (windowWidth - clickCoordsX) < (menuWidth ? menuWidth + 4 : 0) ) {
//     return windowWidth - menuWidth + 'px';
//   } else {
//     return clickCoordsX + 'px';
//   }
// }

// function topValue(clickCoordsY, menuHeight, relatedIdentifier) {
//   let windowHeight = window.innerHeight;
//   let relatedHeight = $(relatedIdentifier).length == 0 ? 0 :  $(relatedIdentifier).offset().top;

//   if ( (windowHeight - clickCoordsY) < (menuHeight ? menuHeight + 4 : 0) ) {
//     return  windowHeight - menuHeight - relatedHeight + 'px';
//   } else {
//     return  clickCoordsY - relatedHeight + 'px';
//   }
// }
