import { Controller } from "stimulus"
import { showModal, selectAllCheckboxes, errorsList, showToastr, bootboxConfirm, initializeSelect2 } from "../helpers/utils"
import { getJsonRequest } from "../helpers/request"
import Rails from 'rails-ujs'

export default class extends Controller {
  static targets = [
    "selectAll", "newsIndexCheckbox", "editSelected",
    "noMultipleEditTemplate", "newNewsBtn", "newNewsFormTemplate", "selectedIds",
    "activateSelected", "deactivateSelected", "deleteSelected", "newsTypeDropdown"
  ]

  initialize() {}

  connect() {
    if (this.hasNewsTypeDropdownTarget) {
      initializeSelect2('.news_type-dropdown', { select: this.onNewsTypeSelect }, { minimumResultsForSearch: -1, placeholder: selectText })
    }
  }

  selectAllRecords(event) {
    let target = event.target;
    selectAllCheckboxes(this.newsIndexCheckboxTargets, target.checked)
    this.enableOrDisableNews()
  }

  selectRecord(event) {
    this.enableOrDisableNews()
  }

  enableOrDisableNews() {
    if (this.newsIndexCheckboxTargets.some(chkbox => chkbox.checked)) {
      this.deleteSelectedTarget.classList.remove('disabled-news')
      this.editSelectedTarget.classList.remove('disabled-news')
    } else {
      this.deleteSelectedTarget.classList.add('disabled-news')
      this.editSelectedTarget.classList.add('disabled-news')
    }
  }

  /* BULK ACTION */

  fetchSelectedIds() {
    let that = this
    let selectedIds = []
    let selectedCount = 0
    that.newsIndexCheckboxTargets.forEach((el, i) => {
      if (el.checked) {
        selectedIds.push(el.getAttribute('value'))
        selectedCount = selectedCount + 1
      }
    })
    if (selectedCount >= 1) {
      that.selectedIdsTarget.value = JSON.stringify(selectedIds)
    }

    return selectedIds
  }

  showBootboxConfirm(confirmationText, url) {
    let that = this
    let selectedIds = that.fetchSelectedIds()
    if (selectedIds && selectedIds.length > 0) {
      bootboxConfirm(confirmationText, yesText, noText, function(result) {
        if (result) {
          that.selectedIdsTarget.parentNode.setAttribute('action', url)
          Rails.fire(that.selectedIdsTarget.parentNode, 'submit');
        }
      })
    }
  }

  editNews() {
    let url = this.activateSelectedTarget.dataset.url
    let selectedIds = this.fetchSelectedIds()
    let selectedCount = selectedIds.length
    if (selectedCount == 0) { return }
    if (selectedCount == 1) {
      // window.location.replace original method changed to open in _blank
      window.open(newsBasePath + '/' + selectedIds[0] + '/edit')
    } else {
      toastr.error(noMultipleEditText)
    }
  }

  activateNews() {
    let url = this.activateSelectedTarget.dataset.url
    this.showBootboxConfirm(activateConfirmationText, url)
  }

  deactivateNews() {
    let url = this.deactivateSelectedTarget.dataset.url
    this.showBootboxConfirm(deactivateConfirmationText, url)
  }

  deleteNews() {
    let url = this.deleteSelectedTarget.dataset.url
    this.showBootboxConfirm(deleteConfirmationText, url)
  }

  batchActionSuccess(event) {
    let [data, status, xhr] = event.detail;
    if (data.success) {
      window.location.reload()
    } else {
      this.batchActionError(event)
    }
  }

  batchActionError(event) {
    let [data, status, xhr] = event.detail;
    let el = event.target.querySelector('.error-list-container')
    if (!data.success) {
      if (el) {
        el.innerHTML = errorsList(data.errors)
      } else {
        showToastr(errorTitle, errorsList(data.errors), 'error')
      }
    }
  }

  onNewsTypeSelect(event) {
    let eventPanel = document.getElementById('event-settings-panel')
    if (event.target.value == eventTypeId) {
      eventPanel.classList.remove('not-clickable')
    } else {
      eventPanel.classList.add('not-clickable')
      document.getElementById('start_date-datepicker').value = ''
      document.getElementById('start_time-timepicker').value = ''
      document.getElementById('end_date-datepicker').value = ''
      document.getElementById('end_time-timepicker').value = ''
      document.getElementById('event-settings-toggle').checked = false
      document.getElementById('start-date-time-wrapper').classList.add('not-clickable')
      document.getElementById('end-date-time-wrapper').classList.add('not-clickable')
    }
  }

  toggleEventSettings(event) {
    this.changeDateTimeWrapperStatus(event.target.checked)
  }

  changeDateTimeWrapperStatus(activate) {
    if (activate) {
      document.getElementById('start-date-time-wrapper').classList.remove('not-clickable')
      document.getElementById('end-date-time-wrapper').classList.remove('not-clickable')
    } else {
      this.resetDateTimePickers()
      document.getElementById('start-date-time-wrapper').classList.add('not-clickable')
      document.getElementById('end-date-time-wrapper').classList.add('not-clickable')
    }
  }

  resetDateTimePickers() {
    document.getElementById('start_date-datepicker').value = ''
    document.getElementById('start_time-timepicker').value = ''
    document.getElementById('end_date-datepicker').value = ''
    document.getElementById('end_time-timepicker').value = ''
  }

  /* BULK ACTION */

  newNews() {
    window.location.replace(newsBasePath + '/new')
  }

  // renderModal(url) {
  //   getJsonRequest(url).then(response => {
  //     if(response.success) {
  //       showModal(response.html, 'atalisModal', 'atalisModal')
  //       $('.select2-dropdown').select2({
  //         placeholder: select2PlaceHolder
  //       });
  //     }
  //   })
  // }
}
