/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

global.gijgoDefaultFormat = 'dd/mm/yyyy';
global.momentDefaultFormat = 'DD/MM/YYYY';
global.showProgramSaveWarning = false;

import Rails from 'rails-ujs';
Rails.start();

require('bootstrap/dist/js/bootstrap');
require('toastr')
require('bootbox')
require('select2')
require('bootstrap-tagsinput/dist/bootstrap-tagsinput')
require('jquery-ui/ui/widgets/tabs')
require('jquery-ui/ui/widgets/accordion')
require('cocoon/app/assets/javascripts/cocoon')
require('selectize/dist/js/selectize')
require('multiple-select/dist/multiple-select')

import 'stylesheets/application'
import 'controllers'
