import { Controller } from "stimulus"
import { showModal, selectAllCheckboxes, errorsList, showToastr, bootboxConfirm, initializeSelect2, initializeMultiSelect, getTemplateContent, formChanged, setHashFragment, initTinymce, toggleSlideArrow } from "../helpers/utils"
import { getJsonRequest } from "../helpers/request"
import Rails from 'rails-ujs'

const defaultGijgoConf = {
  format: global.gijgoDefaultFormat,
  weekStartDay: 1,
  uiLibrary: 'bootstrap4'
}

export default class extends Controller {
  static targets = [
    "selectAll", "programsControlIndexCheckbox", "editSelected",
    "noMultipleEditTemplate", "newProgramsBtn", "newProgramsFormTemplate", "selectedIds",
    "activateSelected", "deactivateSelected", "duplicateSelected", "deleteSelected", "programsTypeDropdown",
    'programForm', 'profileDropdown', 'versionTitle', 'contentTitle', 'programFilter',
    'programListTab'
  ]

  initialize() {}

  connect() {
    // this.initializeFormDropdowns()
    // this.initializeFilterDropdowns()
    // this.initializeCocoonCallbacks()
    // this.initializeFormElementCallbacks()
    this.trackFormChange()
    this.showTab()
    this.initSlideEvent()
  }

  initializeFilterDropdowns() {
    if (this.hasProgramFilterTarget) {
      initializeMultiSelect('.profile-dropdown', {}, { single: true, placeholder: selectText, fireOnSelect: { field: 'p' } })
      initializeMultiSelect('.university-dropdown', { onChange: this.onUniversitySelect }, { minimumCountSelected: 3, dropWidth: 126, placeholder: select2PlaceHolder })
      initializeMultiSelect('.department-dropdown', {}, { minimumCountSelected: 3, filter: true, placeholder: select2PlaceHolder })
      initializeMultiSelect('.area-dropdown', {}, { single: true, placeholder: selectText, fireOnSelect: { field: 'a' } })
      initializeMultiSelect('.type-dropdown', {}, { single: true, placeholder: selectText, fireOnSelect: { field: 't' } })
      initializeMultiSelect('.funding-dropdown', {}, { single: true, placeholder: selectText, fireOnSelect: { field: 'f' } })
      initializeMultiSelect('.year-dropdown', {}, { single: true, placeholder: selectText, fireOnSelect: { field: 'y' } })
      initializeMultiSelect('.eligibility-dropdown', {}, { single: true, placeholder: selectText, fireOnSelect: { field: 'e' } })
      initializeMultiSelect('.ambit-dropdown', {}, { single: true, placeholder: selectText, fireOnSelect: { field: 'b' } })
    }
  }

  initializeFormDropdowns() {
    // Multi selection dropdowns
    initializeMultiSelect('.program-profile-dropdown', {}, { minimumCountSelected: 3, dropWidth: 174, placeholder: selectText })
    initializeMultiSelect('.customer-department-dropdown', {}, { minimumCountSelected: 3, filter: true, placeholder: select2PlaceHolder })
    initializeMultiSelect('.program-area-dropdown', {}, { minimumCountSelected: 3, dropWidth: 200, placeholder: selectText })
    initializeMultiSelect('.program-funding-dropdown', {}, { minimumCountSelected: 3, dropWidth: 200, placeholder: selectText })
    initializeMultiSelect('.program-type-dropdown', {}, { minimumCountSelected: 3, dropWidth: 235, placeholder: selectText })
    // Single selection dropdowns
    initializeMultiSelect('.program-year-dropdown', {}, { single: true, placeholder: selectText })
    initializeMultiSelect('.program-eligibility-dropdown', {}, { single: true, placeholder: selectText })
    initializeMultiSelect('.program-ambit-dropdown', {}, { single: true, placeholder: selectText })
    initializeMultiSelect('.program-version-dropdown', {}, { single: true, placeholder: selectText, allSelectedMessage: 'Current version selected', noMatchMessage: noVersionMatchMessage })
    initializeMultiSelect('.customer-news-type-dropdown', {}, { single: true, placeholder: selectText })
    initializeMultiSelect('.customer-ambit-dropdown', {}, { single: true, placeholder: selectText })
    initializeMultiSelect('.customer-university-dropdown', {}, { single: true, placeholder: selectText, freezeOnLoad: true })
  }

  initializeFormElementCallbacks() {
    $('select.program-version-dropdown').on('change', function() {
      let href = new URL(window.location.href)
      href.searchParams.set('version_id', $(this).val())
      window.location.href = href
    });
  }

  initializeCocoonCallbacks() {
    let that = this
    $('#program-customers-wrapper')
      .on('cocoon:after-insert', function(e, added_customer) {
        $('#programs-general-add-customer-btn').hide()

        let uniqueId = Math.random().toString(36).substr(2, 9) + '-' + Date.now() + '-' + (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
        let newsTypeId = $(added_customer).find('.customer-news-type-dropdown')[0].id
        let departmentId = $(added_customer).find('.customer-department-dropdown')[0].id
        let ambitId = $(added_customer).find('.customer-ambit-dropdown')[0].id
        let universityId = $(added_customer).find('.customer-university-dropdown')[0].id

        $(added_customer).find('.customer-attributes').attr('id', 'customer-' + uniqueId)
        $('#' + universityId).attr('data-customerblockid', uniqueId)
        $(added_customer).find('.slide-arrow').attr('data-target', '#customer-' + uniqueId)
        $(added_customer).find('.customer-department-dropdown').addClass('customer-department-dropdown-' + uniqueId)

        that.removeSelectedCustomers($('#' + universityId))
        // Multiple
        initializeMultiSelect('#' + departmentId, {}, { minimumCountSelected: 3, filter: true, placeholder: select2PlaceHolder })
        // Single
        initializeMultiSelect('#' + universityId, { onChange: that.onUniversitySelect }, { single: true, placeholder: selectText, allSelectedMessage: '', freezeOnSelect: true })
        initializeMultiSelect('#' + newsTypeId, {}, { single: true, placeholder: selectText })
        initializeMultiSelect('#' + ambitId, {}, { single: true, placeholder: selectText })

        toggleSlideArrow($(added_customer).find('.slide-arrow'))

        $('select.customer-university-dropdown').on('change', function(e) {
          $('#add-customer-scoped-info-btn').click()
          $('#programs-general-add-customer-btn').show()

          let $addedBlock = $("#program-customer-scoped-info-wrapper .nested-fields").last()
          $addedBlock.find('.customer-scoped-info-label').text($(e.target).find('option:selected').html())
          $addedBlock.find('.customer_scoped_info_university_id').val(e.target.value)
          $addedBlock.find('.remove-customer-info-btn').addClass('remove-customer-info-btn-' + e.target.value)

          initTinymce('#' + $addedBlock.find('.customer_info').attr('id'))
          that.trackFormChange()
        });
      })

    $('#program-customers-wrapper')
      .on('cocoon:after-remove', function(e, removed_customer) {
        let $university = $(removed_customer).find('select.customer-university-dropdown')
        $('.remove-customer-info-btn-' + $university.val()).click()
      });
  }

  removeSelectedCustomers(target) {
    let selectedValues = []
    let $univDropDown = target[0]
    $("select.customer-university-dropdown").each(function(index, el) {
      if (el != $univDropDown) {
        $('#' + $univDropDown.id + ' option[value="' + el.value + '"]').remove()
      }
    })
  }

  selectAllRecords(event) {
    let target = event.target;
    selectAllCheckboxes(this.programsControlIndexCheckboxTargets, target.checked)
    this.enableOrDisablePrograms()
    this.showOrHideTopMenu()
  }

  selectRecord(event) {
    this.enableOrDisablePrograms()
    this.showOrHideTopMenu()
  }

  enableOrDisablePrograms() {
    if (this.programsControlIndexCheckboxTargets.some(chkbox => chkbox.checked)) {
      this.deleteSelectedTarget.classList.remove('disabled-programs')
      this.editSelectedTarget.classList.remove('disabled-programs')
    } else {
      this.deleteSelectedTarget.classList.add('disabled-programs')
      this.editSelectedTarget.classList.add('disabled-programs')
    }
  }

  showOrHideTopMenu() {
    if (this.programsControlIndexCheckboxTargets.some(chkbox => chkbox.checked)) {
      $(event.target).parents('.table-wrapper').find('.top-menu').removeClass('hidden')
    } else {
      $(event.target).parents('.table-wrapper').find('.top-menu').addClass('hidden')
    }
  }

  /* BULK ACTION */

  fetchSelectedIds() {
    let that = this
    let selectedIds = []
    let selectedCount = 0
    that.programsControlIndexCheckboxTargets.forEach((el, i) => {
      if (el.checked) {
        selectedIds.push(el.getAttribute('value'))
        selectedCount = selectedCount + 1
      }
    })
    if (selectedCount >= 1) {
      that.selectedIdsTarget.value = JSON.stringify(selectedIds)
    }

    return selectedIds
  }

  showBootboxConfirm(confirmationText, url) {
    let that = this
    let selectedIds = that.fetchSelectedIds()
    if (selectedIds && selectedIds.length > 0) {
      bootboxConfirm(confirmationText, yesText, noText, function(result) {
        if (result) {
          that.selectedIdsTarget.parentNode.setAttribute('action', url)
          Rails.fire(that.selectedIdsTarget.parentNode, 'submit');
        }
      })
    }
  }

  editProgramControls() {
    let selectedIds = this.fetchSelectedIds()
    let selectedId = selectedIds[0]
    let selectedCount = selectedIds.length
    let selectedCheckbox = $('#squareControlCheckbox_' + selectedId)
    let currentCallId = selectedCheckbox.data('call-id') || 0
    let currentProgramId = selectedCheckbox.data('program-id')
    if (selectedCount == 0) { return }
    if (selectedCount == 1) {
      window.location.replace(programsBasePath + '/' + currentProgramId + '/edit')
    } else {
      toastr.error(noMultipleEditText)
    }
  }

  editProgramCalls() {
    if (event.target.dataset.actionName == 'call_title_click') {
      $(event.target).parent('table').find('input.programs-index-checkbox').attr('checked', false)
      $(event.target).parent('tr').find('input.programs-index-checkbox').attr('checked', 'checked')
    }
    let selectedIds = this.fetchSelectedIds()
    let selectedId = selectedIds[0]
    let selectedCount = selectedIds.length
    let selectedCheckbox = $('#squareControlCheckbox_' + selectedId)
    let currentCallId = event.target.dataset.callId || selectedCheckbox.data('call-id') || 0
    let versionId = selectedCheckbox.data('version-id') || event.target.dataset.versionId || 0
    let currentProgramId = selectedCheckbox.data('program-id')
    if (selectedCount == 0 || currentCallId == 0) { return }
    if (selectedCount == 1) {
      this.renderModal(programsBasePath + '/' + currentProgramId + '/quick_edit?version_id=' + versionId + '&call_id=' + currentCallId)
    } else {
      toastr.error(noMultipleEditText)
    }
  }

  renderModal(url) {
    let that = this
    getJsonRequest(url).then(response => {
      if (response.success) {
        showModal(response.html, "atalisModal", "atalisModal")
        this.initializeDatepickers()
      }
    })
  }

  initializeDatepickers() {
    let that = this;
    $("#opens_at-datepicker").datepicker(defaultGijgoConf);
    $("#deadline-datepicker").datepicker(
      {
        format: global.gijgoDefaultFormat, uiLibrary: 'bootstrap4',
        close: function() {
          that.deadlineChange()
        }
      }
    );
    $("#phase_2_deadline-datepicker").datepicker(defaultGijgoConf);
    $("#resoultion_date-datepicker").datepicker(defaultGijgoConf);
    $(".eoi_deadline-datepicker").each(function(index, el) {
      $(el).datepicker(defaultGijgoConf);
    });
    $(".internal_deadline-datepicker").each(function(index, el) {
      $(el).datepicker(defaultGijgoConf);
    });
  }

  activateProgramControls() {
    let url = this.activateSelectedTarget.dataset.url
    this.showBootboxConfirm(activateConfirmationText, url)
  }

  deactivateProgramControls() {
    let url = this.deactivateSelectedTarget.dataset.url
    this.showBootboxConfirm(deactivateConfirmationText, url)
  }

  deactivateOrActivateProgram() {
    $(event.target).parents('table').find('input[type=checkbox]').attr('checked', false)
    $(event.target).parents('tr').find('input[type=checkbox]').attr('checked', 'checked')
    if($(event.target).hasClass('hide')) {
      this.activateProgramControls()
    } else {
      this.deactivateProgramControls()
    }
  }

  duplicatePrograms() {
    let url = this.duplicateSelectedTarget.dataset.url
    this.showBootboxConfirm(duplicateConfirmationText, url)
  }

  deletePrograms() {
    let url = this.deleteSelectedTarget.dataset.url
    this.showBootboxConfirm(deleteConfirmationText, url)
  }

  batchActionSuccess(event) {
    let [data, status, xhr] = event.detail;
    if (data.success) {
      window.location.reload()
    } else {
      this.batchActionError(event)
    }
  }

  batchActionError(event) {
    let [data, status, xhr] = event.detail;
    let el = event.target.querySelector('.error-list-container')
    if (!data.success) {
      if (el) {
        el.innerHTML = errorsList(data.errors)
      } else {
        showToastr(errorTitle, errorsList(data.errors), 'error')
      }
    }
  }

  deleteDocumentSuccess(event) {
    let [data, status, xhr] = event.detail;

    if (data.success) {
      event.target.parentElement.remove();
      event.stopPropagation();
    }
  }

  onProgramsTypeSelect(event) {
    let eventPanel = document.getElementById('event-settings-panel')
    if (event.target.value == eventTypeId) {
      eventPanel.classList.remove('not-clickable')
    } else {
      eventPanel.classList.add('not-clickable')
      document.getElementById('start_date-datepicker').value = ''
      document.getElementById('start_time-timepicker').value = ''
      document.getElementById('end_date-datepicker').value = ''
      document.getElementById('end_time-timepicker').value = ''
      document.getElementById('event-settings-toggle').checked = false
      document.getElementById('start-date-time-wrapper').classList.add('not-clickable')
      document.getElementById('end-date-time-wrapper').classList.add('not-clickable')
    }
  }

  onUniversitySelect(e) {
    let data = $(e.target).data();
    let departmentCls;
    if (data.context == 'programForm') {
      let blockId = this.getAttribute('data-customerblockid')
      departmentCls = 'select.customer-department-dropdown-' + blockId
    } else if (data.context == 'programFilter') {
      departmentCls = 'select.department-filter-dropdown'
    }
    let that = this;
    getJsonRequest(departmentsPath + '?page=1&per_page=1000&university_id=' + $(e.target).val()).then(response => {
      if (response.success) {
        let options = getTemplateContent('selectOptions', { options: response.departments, emptyOption: false })
        that.form.querySelector(departmentCls).innerHTML = options
        initializeMultiSelect(departmentCls, {}, { refresh: true, filter: true, placeholder: select2PlaceHolder })
      }
    })
  }

  toggleEventSettings(event) {
    this.changeDateTimeWrapperStatus(event.target.checked)
  }

  changeDateTimeWrapperStatus(activate) {
    if (activate) {
      document.getElementById('start-date-time-wrapper').classList.remove('not-clickable')
      document.getElementById('end-date-time-wrapper').classList.remove('not-clickable')
    } else {
      this.resetDateTimePickers()
      document.getElementById('start-date-time-wrapper').classList.add('not-clickable')
      document.getElementById('end-date-time-wrapper').classList.add('not-clickable')
    }
  }

  resetDateTimePickers() {
    document.getElementById('start_date-datepicker').value = ''
    document.getElementById('start_time-timepicker').value = ''
    document.getElementById('end_date-datepicker').value = ''
    document.getElementById('end_time-timepicker').value = ''
  }

  copyToVersionTitle(e) {
    this.versionTitleTarget.value = e.target.value
  }

  /* BULK ACTION */

  newPrograms() {
    window.location.replace(programsBasePath + '/new')
  }

  trackFormChange() {
    formChanged('#program-form')
  }

  showTab() {
    if (this.hasProgramFormTarget && fragmentHistory) {
      setHashFragment(localStorage.getItem('programs-form-hash-fragment'))
    }

    if (this.hasProgramListTabTarget && fragmentHistory) {
      setHashFragment(localStorage.getItem('programs-list-hash-fragment'))
    }
  }

  initSlideEvent() {
    if (this.hasProgramFormTarget) {
      toggleSlideArrow($('.slide-arrow'))
    }
  }

  radioButtonFilter(event) {
    let field = event.target.getAttribute('data-field')
    let value = event.target.getAttribute('data-value')
    let url = new URL(window.location.href);
    url.searchParams.set(field, value);
    window.location.replace(url)
  }
}
