import {Controller} from 'stimulus'

let selectedCalls = []

export default class extends Controller {

  static targets = ['list', 'topic', 'name', 'email', 'type' ,'submit']

  addCall(event) {
    event.preventDefault()
    let name = event.target.getAttribute('data-name')
    let id = event.target.getAttribute('data-id')

    if (selectedCalls.includes(id)) {
      console.log('Already there')
    } else {
      selectedCalls.push(id)
      // console.log('element added:', selectedCalls)
      let element = this.elementTemplate(name, id)
      this.listTarget.insertAdjacentHTML('beforeend', element)
      this.topicTarget.disabled = false
      this.nameTarget.disabled = false
      this.emailTarget.disabled = false
      this.submitTarget.disabled = false
    }
  }

  removeCall(event) {
    event.preventDefault()
    let id = event.target.getAttribute('data-id')
    if (selectedCalls.includes(id)) {
      let i = selectedCalls.indexOf(id)
      if (i !== -1) {
        selectedCalls.splice(i, 1)
        let item = event.target.closest('.call')
        item.remove()
        if (selectedCalls.length === 0) {
          this.topicTarget.disabled = true
          this.nameTarget.disabled = true
          this.emailTarget.disabled = true
          this.submitTarget.disabled = true
        }
      }
      // console.log('after remove:', selectedCalls)
    } else {
      console.log('Element is not there')
    }
  }

  sendEOI(event) {
    event.preventDefault()
    let data = {
      calls: selectedCalls,
      name: this.nameTarget.value,
      topic: this.topicTarget.value,
      email: this.emailTarget.value,
      participation_type: this.typeTarget.value,
    }
    if (data.email !== "" && data.name !== "" && data.topic !== "") {
      $.ajax({
        url: '/programs/send_eoi',
        type: "POST",
        dataType: "json",
        data: data,
        error: function() {
          console.log("error")
        },
        success: function(res) {
          console.log("sent!")
          document.querySelector("[data-dismiss='modal']").click()
        }
      });
    } else {
      if(data.name === ""){
        $("#name").css("border", "1px solid red");
      } else {
        $("#name").css("border", "1px solid green");
      }
      if(data.email === ""){
        $("#email").css("border", "1px solid red");
      } else {
        $("#email").css("border", "1px solid green");
      }
      if(data.topic === ""){
        $("#eoi-topic").css("border", "1px solid red");
      } else {
        $("#eoi-topic").css("border", "1px solid green");
      }
    }
  }

  elementTemplate(name, id) {
    return `<li class="call"> 
              ${name} 
              <a href="#" data-action="click->eoi#removeCall">
                <span class="icon icon--close" data-id="${id}" style="font-size: 1.3rem !important"></span>
              </a>
            </li>`
  }

}
