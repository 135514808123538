import { Controller } from "stimulus"

import {
  bootboxConfirm
} from '../helpers/utils'

export default class extends Controller {
  static targets = ['recordFilter', 'sortRecords', 'hiddenKey']

  initialize() {}

  connect() {}

  // Search record
  searchRecord(event) {
    let keycode = (event.keyCode ? event.keyCode : event.which);
    if (this.hasHiddenKeyTarget) {
      this.hiddenKeyTarget.value = event.target.value;
    }
    if (keycode == '13') {
      let filterBy = event.target.getAttribute('data-filter-by') || 'filter'
      this.filterRecord('', filterBy, event)
    }
  }

  // Sort records
  sortRecord(event) {
    let sortBy = event.target.getAttribute('data-sortparam') + '_' + event.target.getAttribute('data-sortdir')
    let filterBy = event.target.getAttribute('data-filter-by') || 'filter'
    this.filterRecord(sortBy, filterBy, event)
    this.changeSortDirection(event.target)
    this.toggleSortArrow(event.target)
  }

  filterRecord(sortBy, filterBy, event) {
    let that = this
    if(global.showProgramSaveWarning) {
      bootboxConfirm(programSaveConfirmationText, 'Continue', 'Cancel', function(result) {
          if (result) {
            that.triggerFilter(sortBy, filterBy, event)
          } else {
            return
          }
      })
    } else {
      this.triggerFilter(sortBy, filterBy, event)
    }
  }

  triggerFilter(sortBy, filterBy, event) {
    const recordFilterBox = this.recordFilterTarget
    let params = new URLSearchParams(location.search)
    params.set(filterBy, recordFilterBox.value)
    if (sortBy.length > 0) { params.set('sort_by', sortBy) }
    if (event.target.dataset.searchurl) {
      window.location = event.target.dataset.searchurl + '?' + params.toString()
    } else {
      window.location.search = params.toString()
    }
  }

  changeSortDirection(target) {
    if (target.getAttribute('data-sortdir') == 'desc') {
      target.setAttribute('data-sortdir', 'asc')
    } else {
      target.setAttribute('data-sortdir', 'desc')
    }
  }

  toggleSortArrow(target) {
    target.classList.toggle('down')
    target.classList.toggle('up')
  }
}
