import { Controller } from "stimulus"
import { showModal, selectAllCheckboxes, errorsList, showToastr, bootboxConfirm } from "../helpers/utils"
import { getJsonRequest } from "../helpers/request"
import Rails from 'rails-ujs'

export default class extends Controller {
  static targets = [
    "selectAll", "programCommentIndexCheckbox", "editSelected",
    "noMultipleEditTemplate", "newProgramCommentBtn", "newProgramCommentFormTemplate", "selectedIds",
    "activateSelected", "deactivateSelected", "deleteSelected"
  ]

  initialize() {}

  connect() {}

  selectAllRecords(event) {
    let target = event.target;
    selectAllCheckboxes(this.programCommentIndexCheckboxTargets, target.checked)
    this.enableOrDisableProgramComments()
  }

  selectRecord(event) {
    this.enableOrDisableProgramComments()
  }

  enableOrDisableProgramComments() {
    if (this.programCommentIndexCheckboxTargets.some(chkbox => chkbox.checked)) {
      this.deleteSelectedTarget.classList.remove('disabled-program-comment')
      this.editSelectedTarget.classList.remove('disabled-program-comment')
    } else {
      this.deleteSelectedTarget.classList.add('disabled-program-comment')
      this.editSelectedTarget.classList.add('disabled-program-comment')
    }
  }

  /* BULK ACTION */

  fetchSelectedIds() {
    let that = this
    let selectedIds = []
    let selectedCount = 0
    that.programCommentIndexCheckboxTargets.forEach((el, i) => {
      if (el.checked) {
        selectedIds.push(el.getAttribute('value'))
        selectedCount = selectedCount + 1
      }
    })
    if (selectedCount >= 1) {
      that.selectedIdsTarget.value = JSON.stringify(selectedIds)
    }
    // } else {
    //   toastr.error("Please select at least one record")
    // }
    return selectedIds
  }

  showBootboxConfirm(confirmationText, url) {
    let that = this
    let selectedId = that.deleteSelectedTarget.dataset.programCommentId
    if (selectedId) {
      bootboxConfirm(confirmationText, yesText, noText, function(result) {
        var data = { program_comment_ids: JSON.stringify([selectedId]) }
        if (result) {
          fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
              'Content-Type': 'application/json',
              'X-CSRF-Token': Rails.csrfToken()
            },
            credentials: 'same-origin'
          }).then(function(response) {
            window.location.reload()
          }).then(function(data) {
            showToastr(errorTitle, errorsList(data.errors), 'error')
          });
        }
      })
    }
  }

  deleteProgramComment() {
    let url = this.deleteSelectedTarget.dataset.url
    this.showBootboxConfirm(programCommentDeleteConfirmationText, url)
  }

  batchActionSuccess(event) {
    let [data, status, xhr] = event.detail;
    if (data.success) {
      window.location.reload()
    } else {
      this.batchActionError(event)
    }
  }

  batchActionError(event) {
    let [data, status, xhr] = event.detail;
    let el = event.target.querySelector('.error-list-container')
    if (!data.success) {
      if (el) {
        el.innerHTML = errorsList(data.errors)
      } else {
        showToastr(errorTitle, errorsList(data.errors), 'error')
      }
    }
  }

  /* BULK ACTION */

  newProgramComment() {
    let programId = this.newProgramCommentBtnTarget.dataset.programId
    this.renderModal(programCommentsBasePath + '/new?program_id=' + programId)
  }

  renderModal(url) {
    getJsonRequest(url).then(response => {
      if (response.success) {
        showModal(response.html, "atalisModal", "atalisModal")
      }
    })
  }
}
