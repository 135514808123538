import { Controller } from 'stimulus'
import { showModal, selectAllCheckboxes, errorsList, showToastr, bootboxConfirm, getTemplateContent, initializeSelect2, initializeMultiSelect } from '../helpers/utils'
import { getJsonRequest, postJsonRequest } from '../helpers/request'
import Rails from 'rails-ujs'

export default class extends Controller {
  static targets = [
    'selectAll', 'userIndexCheckbox', 'deleteSelected', 'editSelected',
    'noMultipleEditTemplate', 'newUserBtn', 'newUserFormTemplate', 'deleteIds',
    'deactiveConfirmUsername'
  ]

  initialize() {}

  connect() {}

  selectAllRecords(event) {
    let target = event.target;
    selectAllCheckboxes(this.userIndexCheckboxTargets, target.checked)
    this.enableOrDisableLinks()
  }

  selectRecord(event) {
    this.enableOrDisableLinks()
  }

  enableOrDisableLinks() {
    if (this.userIndexCheckboxTargets.some(chkbox => chkbox.checked)) {
      this.deleteSelectedTarget.classList.remove('disabled-link')
      this.editSelectedTarget.classList.remove('disabled-link')
    } else {
      this.deleteSelectedTarget.classList.add('disabled-link')
      this.editSelectedTarget.classList.add('disabled-link')
    }
  }

  editUser() {
    let selectedTarget = null
    let selectedCount = 0
    let that = this;
    this.userIndexCheckboxTargets.forEach((el, i) => {
      if (el.checked) {
        selectedTarget = el
        selectedCount = selectedCount + 1
      }
    })
    if (selectedCount == 0) { return }
    if (selectedCount == 1) {
      this.renderNewOrEdit(usersBasePath + '/' + selectedTarget.getAttribute('value') + '/edit')
    } else {
      toastr.error(noMultipleEditText)
    }
  }

  editProfile() {
    this.renderNewOrEdit(usersBasePath + '/' + currentUserId + '/edit')
  }

  newUser() {
    this.renderNewOrEdit(usersBasePath + '/new')
  }

  renderNewOrEdit(url) {
    let that = this
    getJsonRequest(url).then(response => {
      if (response.success) {
        showModal(response.html, 'atalisModal', 'atalisModal')
        initializeMultiSelect('.user-role-dropdown', { onChange: that.onRoleChange }, { single: true, minimumResultsForSearch: -1, placeholder: selectRoleText })
      }
    })
  }

  deleteSelected() {
    let selectedIds = []
    this.userIndexCheckboxTargets.forEach((el, i) => {
      if (el.checked) {
        selectedIds.push(el.getAttribute('value'))
      }
    })
    this.deleteIdsTarget.value = selectedIds
    let that = this
    bootboxConfirm(deleteConfirmationText, yesText, noText, function(result) {
      if (result && selectedIds.length > 0) {
        Rails.fire(that.deleteIdsTarget.parentNode, 'submit');
      }
    })
  }

  saveUserSuccess(event) {
    let [data, status, xhr] = event.detail;
    if (data.success) {
      window.location.reload();
    } else {
      this.saveUserError(event)
    }
  }

  saveUserError(event) {
    let [data, status, xhr] = event.detail;
    if (!data.success) {
      event.target.querySelector('.error-list-container').innerHTML = errorsList(data.errors)
    }
  }

  deleteUserSuccess(event) {
    let [data, status, xhr] = event.detail;
    if (data.success) {
      window.location.reload();
    } else {
      this.deleteUserError(event)
    }
  }

  deleteUserError(event) {
    let [data, status, xhr] = event.detail;
    if (!data.success) {
      showToastr(errorTitle, data.errors, 'error')
    }
  }

  toggleUserStatus(event) {
    event.preventDefault()
    let userId = event.target.getAttribute('data-user-id')
    let params = {
      checked: event.target.checked ? 1 : 0,
      url: usersBasePath + '/' + userId + '/deactivate'
    }

    if (params.checked) {
      this.activateUser(userId)
    } else {
      let content = getTemplateContent('deactivateUserTemplate', params)
      showModal(content, 'atalisModal', 'atalisModal')
    }
  }

  activateUser(userId) {
    postJsonRequest(
      usersBasePath + '/' + event.target.getAttribute('data-user-id'), { user: { status: true } },
      'PATCH'
    ).then(response => {
      if (response.success) {
        showToastr(successTitle, activateSuccess, 'success')
      }
    }).catch(error => {
      showToastr(errorTitle, activateError, 'error')
    });
  }

  deactivateSuccess(event) {
    let [data, status, xhr] = event.detail;

    if (data.success) {
      window.location.reload()
    } else {
      this.deactivateError(event)
    }
  }

  deactivateError(event) {
    let [data, status, xhr] = event.detail;

    if (!data.success) {
      showToastr(errorTitle, data.errors, 'error')
    }
  }

  sendEmailSuccess(event) {
    event.stopImmediatePropagation()

    let [data, status, xhr] = event.detail;

    if (data.success) {
      showToastr(successTitle, data.message, 'success')
      event.target.disabled = true
    }
  }

  sendEmailError(event) {
    let [data, status, xhr] = event.detail;

    showToastr(errorTitle, sendEmailError, 'error')
  }

  onUniversitySelect(e) {
    let data = $(e.target).data();
    let departmentCls = '.user-ofi-dropdown';
    let that = this;
    getJsonRequest(departmentsPath + '?page=1&per_page=1000&university_id=' + $(e.target).val()).then(response => {
        if (response.success) {
            let options = getTemplateContent('selectOptions', { options: response.departments, emptyOption: false })
            $(departmentCls)[0].innerHTML = options;
        }
    })
  }

  onRoleChange(e) {
    let role = $(e.target).val()
    if(role == 'ofi' || role == 'university_admin') {
      $('.university_and_department_block').removeClass('hidden')
    } else {
      $('.university_and_department_block').addClass('hidden')
      $('.user-university-dropdown').val('')
      $('.user-ofi-dropdown').val('')
    }
  }
}
