import { Controller } from "stimulus"
import { showModal, selectAllCheckboxes, errorsList, showToastr, bootboxConfirm, initializeSelect2, getTemplateContent, copyToClipboard } from "../helpers/utils"
import { getJsonRequest } from "../helpers/request"
import Rails from 'rails-ujs'

export default class extends Controller {
  static targets = [
    "selectAll", "documentIndexCheckbox", "editSelected",
    "noMultipleEditTemplate", "newDocumentBtn", "newDocumentFormTemplate", "selectedIds",
    "activateSelected", "deactivateSelected", "deleteSelected", "sectionsDropDown"
  ]

  initialize() {}

  connect() {}

  selectAllRecords(event) {
    let target = event.target;
    selectAllCheckboxes(this.documentIndexCheckboxTargets, target.checked)
    this.enableOrDisableDocuments()
  }

  selectRecord(event) {
    this.enableOrDisableDocuments()
  }

  enableOrDisableDocuments() {
    if (this.documentIndexCheckboxTargets.some(chkbox => chkbox.checked)) {
      this.deleteSelectedTarget.classList.remove('disabled-document')
      this.editSelectedTarget.classList.remove('disabled-document')
    } else {
      this.deleteSelectedTarget.classList.add('disabled-document')
      this.editSelectedTarget.classList.add('disabled-document')
    }
  }

  /* BULK ACTION */

  fetchSelectedIds() {
    let that = this
    let selectedIds = []
    let selectedCount = 0
    that.documentIndexCheckboxTargets.forEach((el, i) => {
      if (el.checked) {
        selectedIds.push(el.getAttribute('value'))
        selectedCount = selectedCount + 1
      }
    })
    if (selectedCount >= 1) {
      that.selectedIdsTarget.value = JSON.stringify(selectedIds)
    }
    // } else {
    //   toastr.error("Please select at least one record")
    // }
    return selectedIds
  }

  showBootboxConfirm(confirmationText, url) {
    let that = this
    let selectedIds = that.fetchSelectedIds()
    if (selectedIds && selectedIds.length > 0) {
      bootboxConfirm(confirmationText, yesText, noText, function(result) {
        if (result) {
          that.selectedIdsTarget.parentNode.setAttribute('action', url)
          Rails.fire(that.selectedIdsTarget.parentNode, 'submit');
        }
      })
    }
  }

  editDocument() {
    let url = this.activateSelectedTarget.dataset.url
    let selectedIds = this.fetchSelectedIds()
    let selectedCount = selectedIds.length
    if (selectedCount == 0) { return }
    if (selectedCount == 1) {
      this.renderModal(documentsBasePath + '/' + selectedIds[0] + '/edit')
    } else {
      toastr.error(noMultipleEditText)
    }
  }

  activateDocument() {
    let url = this.activateSelectedTarget.dataset.url
    this.showBootboxConfirm(activateConfirmationText, url)
  }

  deactivateDocument() {
    let url = this.deactivateSelectedTarget.dataset.url
    this.showBootboxConfirm(deactivateConfirmationText, url)
  }

  deleteDocument() {
    let url = this.deleteSelectedTarget.dataset.url
    this.showBootboxConfirm(deleteConfirmationText, url)
  }

  batchActionSuccess(event) {
    let [data, status, xhr] = event.detail;
    if (data.success) {
      window.location.reload()
    } else {
      this.batchActionError(event)
    }
  }

  batchActionError(event) {
    let [data, status, xhr] = event.detail;
    let el = event.target.querySelector('.error-list-container')
    if (!data.success) {
      if (el) {
        el.innerHTML = errorsList(data.errors)
      } else {
        showToastr(errorTitle, errorsList(data.errors), 'error')
      }
    }
  }

  /* BULK ACTION */

  newDocument() {
    this.renderModal(documentsBasePath + '/new')
  }

  renderModal(url) {
    getJsonRequest(url).then(response => {
      if (response.success) {
        showModal(response.html, 'atalisModal', 'atalisModal')
        initializeSelect2('.university-dropdown', { select: this.onUniversitySelect })
        initializeSelect2('.section-dropdown')
      }
    })
  }

  onUniversitySelect(e) {
    let data = e.params.data;
    let that = this;
    getJsonRequest(sectionsPath + '?page=1&per_page=1000&university_id=' + data.id).then(response => {
      if (response.success) {
        let options = getTemplateContent('selectOptions', { options: response.sections, emptyOption: true })
        that.form.querySelector('.section-dropdown').innerHTML = options
        initializeSelect2('.section-dropdown')
      }
    })
  }

  copyUrl(event) {
    let target = event.target
    if (copyToClipboard(target.getAttribute('data-documenturl'))) {
      showToastr(successTitle, copySuccessText, 'success')
    }
  }
}
