// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import { postJsonRequest } from "../helpers/request"

export default class extends Controller {
  static targets = ["userLanguage"]

  connect() {
    this.forceDefaultLanguage();
  }

  // This method calls an Ajax request and change user language
  changeUserLanguage(event) {
    let language = this.userLanguageTarget.value || event.target.getAttribute('value');
    postJsonRequest(
      '/user_settings/update_user_language', { user_language: language }
    ).then(response => {
      if (response.success) {
        location.reload();
      }
    })
  }

  forceDefaultLanguage() {
    const session_user_language = this.element.getAttribute("data-session-user-language");
    const frontend_locale = this.element.getAttribute("data-frontend-locale");
    const default_language = this.element.getAttribute("data-default-language");
    if(session_user_language === '') {
      postJsonRequest(
        '/user_settings/update_user_language', { user_language: default_language }
      ).then(response => {
        if (response.success) {
          location.reload();
        }
      })
    }
  }

}
