import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ["list"];

  toggleMobileMenu() {
    if (this.listTarget.classList.contains('d-none')) {
      this.listTarget.classList.remove('d-none')
    } else {
      this.listTarget.classList.add('d-none')
    }
  }

  hideMobileMenu() {
    this.listTarget.classList.add('d-none')
  }

}
