// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
//  application.load(definitionsFromContext(context))

addEventListener('DOMContentLoaded', event => {
  application.load(definitionsFromContext(context))
  $('body').removeClass('hide')
})

// Theme
require('tinymce/themes/silver');
require('tinymce/skins/ui/oxide/skin.min.css');

// Plugins
require('tinymce/plugins/advlist');
require('tinymce/plugins/code');
require('tinymce/plugins/link');
require('tinymce/plugins/lists');
require('tinymce/plugins/hr');
require('tinymce/plugins/paste');
require('tinymce/plugins/visualchars');
require('tinymce/plugins/table');
require('tinymce/plugins/searchreplace');
require('tinymce/plugins/print');
require('tinymce/plugins/preview');

// Initialize
import { initTinymce } from '../helpers/utils'

initTinymce();
