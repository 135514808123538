import { Controller } from "stimulus"
import { showModal, selectAllCheckboxes, errorsList, showToastr, bootboxConfirm } from "../helpers/utils"
import { getJsonRequest } from "../helpers/request"
import Rails from 'rails-ujs'

export default class extends Controller {
  static targets = [
    "selectAll", "notificationIndexCheckbox", "editSelected",
    "noMultipleEditTemplate", "newNotificationBtn", "newNotificationFormTemplate", "selectedIds",
    "activateSelected", "deactivateSelected", "deleteSelected", 'sendMessage'
  ]

  initialize() {}

  connect() {
    this.initializeSelectize()
  }

  selectAllRecords(event) {
    let target = event.target;
    selectAllCheckboxes(this.notificationIndexCheckboxTargets, target.checked)
    this.enableOrDisableNotifications()
  }

  selectRecord(event) {
    this.enableOrDisableNotifications()
  }

  initializeSelectize() {

    var REGEX_EMAIL = '([a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*@' +
      '(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)';

    $('#notification-receiver_emails').selectize({
      persist: false,
      maxItems: null,
      valueField: 'email',
      labelField: 'name',
      searchField: ['name', 'email'],
      create: false,
      render: {
        item: function(item, escape) {
          return '<div>' +
            (item.name ? '<span class="name">' + escape(item.name) + '</span>' : '') +
            (item.email ? ' <<span class="email">' + escape(item.email) + '</span>>' : '') +
            '</div>';
        },
        option: function(item, escape) {
          var label = item.name || item.email;
          var caption = item.name ? item.email : null;
          return '<div>' +
            '<span class="label">' + escape(label) + '</span>' +
            (caption ? ' <<span class="caption">' + escape(caption) + '</span>>' : '') +
            '</div>';
        }
      },
      createFilter: function(input) {
        var match, regex;

        // email@address.com
        regex = new RegExp('^' + REGEX_EMAIL + '$', 'i');
        match = input.match(regex);
        if (match) return !this.options.hasOwnProperty(match[0]);

        // name <email@address.com>
        regex = new RegExp('^([^<]*)\<' + REGEX_EMAIL + '\>$', 'i');
        match = input.match(regex);
        if (match) return !this.options.hasOwnProperty(match[2]);

        return false;
      },
      load: function(query, callback) {
        if (!query.length) return callback();
        $.ajax({
          url: usersBasePath,
          type: "GET",
          dataType: "json",
          data: {
            filter: query,
            limit: 10
          },
          error: function() {
            callback();
          },
          success: function(res) {
            callback(res.users);
          }
        });
      }
    });
  }

  enableOrDisableNotifications() {
    if (this.notificationIndexCheckboxTargets.some(chkbox => chkbox.checked)) {
      this.deleteSelectedTarget.classList.remove('disabled-notification')
      this.editSelectedTarget.classList.remove('disabled-notification')
    } else {
      this.deleteSelectedTarget.classList.add('disabled-notification')
      this.editSelectedTarget.classList.add('disabled-notification')
    }
  }

  /* BULK ACTION */

  fetchSelectedIds() {
    let that = this
    let selectedIds = []
    let selectedCount = 0
    that.notificationIndexCheckboxTargets.forEach((el, i) => {
      if (el.checked) {
        selectedIds.push(el.getAttribute('value'))
        selectedCount = selectedCount + 1
      }
    })
    if (selectedCount >= 1) {
      that.selectedIdsTarget.value = JSON.stringify(selectedIds)
    }
    // } else {
    //   toastr.error("Please select at least one record")
    // }
    return selectedIds
  }

  showBootboxConfirm(confirmationText, url) {
    let that = this
    let selectedIds = that.fetchSelectedIds()
    if (selectedIds && selectedIds.length > 0) {
      bootboxConfirm(confirmationText, yesText, noText, function(result) {
        if (result) {
          that.selectedIdsTarget.parentNode.setAttribute('action', url)
          Rails.fire(that.selectedIdsTarget.parentNode, 'submit');
        }
      })
    }
  }

  editNotification() {
    let url = this.activateSelectedTarget.dataset.url
    let selectedIds = this.fetchSelectedIds()
    let selectedCount = selectedIds.length
    if (selectedCount == 0) { return }
    if (selectedCount == 1) {
      this.renderModal(notificationsBasePath + '/' + selectedIds[0] + '/edit')
    } else {
      toastr.error(noMultipleEditText)
    }
  }

  activateNotification() {
    let url = this.activateSelectedTarget.dataset.url
    this.showBootboxConfirm(activateConfirmationText, url)
  }

  deactivateNotification() {
    let url = this.deactivateSelectedTarget.dataset.url
    this.showBootboxConfirm(deactivateConfirmationText, url)
  }

  deleteNotification() {
    let url = this.deleteSelectedTarget.dataset.url
    this.showBootboxConfirm(deleteConfirmationText, url)
  }

  batchActionSuccess(event) {
    let [data, status, xhr] = event.detail;
    if (data.success) {
      window.location.reload()
    } else {
      this.batchActionError(event)
    }
  }

  batchActionError(event) {
    let [data, status, xhr] = event.detail;
    let el = event.target.querySelector('.error-list-container')
    if (!data.success) {
      if (el) {
        el.innerHTML = errorsList(data.errors)
      } else {
        showToastr(errorTitle, errorsList(data.errors), 'error')
      }
    }
  }

  renderModal(url) {
    getJsonRequest(url).then(response => {
      if (response.success) {
        showModal(response.html, "atalisModal", "atalisModal")
      }
    })
  }

  onRemotePaginationLoad(event) {
    console.log('onRemotePaginationLoad')
    let [data, status, xhr] = event.detail;
    document.getElementById('notification-list-modal').innerHTML = data.html
  }
  /* BULK ACTION */

  newNotification() {
    this.renderModal(notificationsBasePath + '/new')
  }

  listNotifications() {
    this.renderModal(notificationsBasePath)
  }

}
