import { Controller } from "stimulus"
import { showModal, selectAllCheckboxes, errorsList, showToastr, bootboxConfirm } from "../helpers/utils"
import { getJsonRequest } from "../helpers/request"
import Rails from 'rails-ujs'

export default class extends Controller {
    static targets = [
        "selectAll", "acronymIndexCheckbox", "editSelected",
        "noMultipleEditTemplate", "newAcronymBtn", "newAcronymFormTemplate", "selectedIds",
        "activateSelected", "deactivateSelected", "deleteSelected"
    ]

    initialize() {}

    connect() {}

    selectAllRecords(event) {
        let target = event.target;
        selectAllCheckboxes(this.acronymIndexCheckboxTargets, target.checked)
        this.enableOrDisableAcronyms()
    }

    selectRecord(event) {
        this.enableOrDisableAcronyms()
    }

    enableOrDisableAcronyms() {
        if (this.acronymIndexCheckboxTargets.some(chkbox => chkbox.checked)) {
            this.deleteSelectedTarget.classList.remove('disabled-acronym')
            this.editSelectedTarget.classList.remove('disabled-acronym')
        } else {
            this.deleteSelectedTarget.classList.add('disabled-acronym')
            this.editSelectedTarget.classList.add('disabled-acronym')
        }
    }

    /* BULK ACTION */

    fetchSelectedIds() {
        let that = this
        let selectedIds = []
        let selectedCount = 0
        that.acronymIndexCheckboxTargets.forEach((el, i) => {
            if (el.checked) {
                selectedIds.push(el.getAttribute('value'))
                selectedCount = selectedCount + 1
            }
        })
        if (selectedCount >= 1) {
            that.selectedIdsTarget.value = JSON.stringify(selectedIds)
        }
        // } else {
        //   toastr.error("Please select at least one record")
        // }
        return selectedIds
    }

    showBootboxConfirm(confirmationText, url) {
        let that = this
        let selectedIds = event.target.getAttribute('data-acronym-id')
        if (selectedIds && selectedIds.length > 0) {
            bootboxConfirm(confirmationText, yesText, noText, function(result) {
                var data = { acronym_ids: JSON.stringify([selectedIds]) }
                if (result) {
                    fetch(url, {
                        method: 'POST',
                        body: JSON.stringify(data),
                        headers: {
                            'Content-Type': 'application/json',
                            'X-CSRF-Token': Rails.csrfToken()
                        },
                        credentials: 'same-origin'
                    }).then(function(response) {
                        window.location.reload()
                    }).then(function(data) {
                        showToastr(errorTitle, errorsList(data.errors), 'error')
                    });
                }
            })
        }
    }

    // editAcronym() {
    //   let selectedIds = event.target.getAttribute('data-acronym-id')
    //   let acronymName = event.target.parentNode.innerText
    //   let selectedCount = selectedIds.length
    //   if (selectedCount == 0) { return }
    //   if (selectedCount == 1) {
    //     this.renderModal(acronymsBasePath + '/' + selectedIds + '/edit', acronymName)
    //   } else {
    //     toastr.error(noMultipleEditText)
    //   }
    // }

    editAcronym() {
        let selectedId = event.target.getAttribute('data-acronym-id')
        this.renderModal(acronymsBasePath + '/' + selectedId + '/edit')
    }

    deleteAcronym() {
        let url = event.target.getAttribute('data-url')
        this.showBootboxConfirm(acronymDeleteConfirmationText, url)
    }

    batchActionSuccess(event) {
        let modal = document.querySelector('#atalisModal');
        let [data, status, xhr] = event.detail;
        if (data.success) {
            let el = document.querySelector('#hidden-acronym-ids')
            if (data.action === 'create') {
                el.innerHTML += "<input type='hidden' name='program[acronym_ids][]' value='" + data.acronym.id + "'>"
                let acronymEl = $('.acronym-list')
                let acronymLi = `<li data-acronym-id="${data.acronym.id}">
                           <label class="upcase">${data.acronym.name}</label>
                           <div class="small-edit" title="Edit" data-acronym-id="${data.acronym.id}" data-target="acronyms.editSelected" data-action="click->acronyms#editAcronym"></div>
                           <div class="small-dele" title="Delete" data-acronym-id="${data.acronym.id}" data-url="${acronymsDeletePath}" data-target="acronyms.deleteSelected" data-action="click->acronyms#deleteAcronym"></div>
                         </li>`
                acronymEl.append(acronymLi)
            } else if (data.action === 'update') {
                $(`.acronym-list li[data-acronym-id="${data.acronym.id}"] label`).html(data.acronym.name);
            }
            document.querySelector('#atalisModal .close-button').click()
            showToastr(successTitle, acronymSuccessText, 'success')
        } else {
            this.batchActionError(event)
        }
    }

    batchActionError(event) {
        let [data, status, xhr] = event.detail;
        let el = event.target.querySelector('.error-list-container')
        if (!data.success) {
            if (el) {
                el.innerHTML = errorsList(data.errors)
            } else {
                showToastr(errorTitle, errorsList(data.errors), 'error')
            }
        }
    }

    /* BULK ACTION */

    // newAcronym() {
    //   var currentElement = event.target
    //   var currentEditorId = currentElement.getAttribute('data-editor-id');
    //   var element = document.querySelector('#' + currentEditorId)
    //   var editor = element.editor

    //   var selectedAttributes = new Set
    //   var acronymName = ''

    //   var selectedRange = editor.getSelectedRange()
    //   if (selectedRange[0] === selectedRange[1]) { selectedRange[1]++ }

    //   var selectedPieces = editor.getDocument().getDocumentAtRange(selectedRange).getPieces()
    //   selectedPieces.forEach(function(piece) {
    //     Object.keys(piece.getAttributes()).forEach(function(attribute) {
    //       if (attribute === 'acronym') {
    //         acronymName = piece.string
    //         selectedAttributes.add(attribute)
    //       }
    //     })
    //   })

    //   if (acronymName) {
    //     this.renderModal(acronymsBasePath + '/new', acronymName)
    //   }
    // }

    // renderModal(url, acronymName) {
    //   getJsonRequest(url).then(response => {
    //     if (response.success) {
    //       showAcronymModal(response.html, "atalisModal", "atalisModal", acronymName)
    //     }
    //   })
    // }

    newAcronym() {
        this.renderModal(acronymsBasePath + '/new')
    }

    renderModal(url) {
        getJsonRequest(url).then(response => {
            if (response.success) {
                showModal(response.html, "atalisModal", "atalisModal")
            }
        })
    }
}

// export function showAcronymModal(content, modalId = "atalisModal", contentId = "atalisModalContent", title = "") {
//   console.log("title", title)
//   let modal = document.getElementById(modalId);
//   if (content.length > 0) {
//     document.getElementById(contentId).innerHTML = content
//   }
//   document.body.classList.add("modal-open")

//   document.querySelector('.modal-title').innerHTML = title

//   var acronymNameEl = document.querySelector('#acronym-name')
//   acronymNameEl.parentNode.classList.add('hide')
//   acronymNameEl.value = title

//   modal.classList.add("show")
// }