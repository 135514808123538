import { metaValue } from "./utils"

// Post AJAX call
export function postJsonRequest(url, params, method = 'POST') {
  return fetch(url, {
    body: JSON.stringify(params),
    method: method,
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': metaValue('csrf-token')
    }
  }).then(response => response.json())
}

// GET AJAX call
export function getJsonRequest(url, params) {
  return fetch(url, {
    method: 'GET',
    dataType: 'json',
    credentials: 'include',
    headers: {
      'X-CSRF-Token': metaValue('csrf-token')
    }
  }).then(response => response.json())
}

// Returns the JSON data for post Ajax request
export function prepareJsonData(data) {
  console.log(data)
  let formData = new FormData()
  for (var key in data) {
    formData.append(key, data[key]);
    console.log(formData)
  }
  console.log(JSON.stringify(formData))
  return JSON.stringify(formData)
}
