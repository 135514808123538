import { Controller } from "stimulus"

import { setHashFragment } from "../helpers/utils"

export default class extends Controller {
  static targets = [
    'tabsWrapper'
  ]

  connect() {
    if (this.hasTabsWrapperTarget) {
      $(this.tabsWrapperTarget).tabs({
        beforeActivate: function(event, ui) {
          let hashFragment = ui.newPanel[0].id
          if (event.target.dataset.hashFragmentHistory == 'true') {
            let key = event.target.dataset.hashFragmentKey
            localStorage.setItem(key, hashFragment)
          }
          setHashFragment(hashFragment)
        },
        create: function(event, ui) {
          $('.new-programs-tab').removeClass('hide');
        }
      })
    }
  }
}
