import { Controller } from 'stimulus';
import { disableOrEnableButton, showOrHideLoader, showToastr, createLoaderWrapper } from '../helpers/utils'
import { positionMenu } from '../helpers/td_popup_menu'
import { LinksController } from './links_controller'

export default class extends Controller {
  static targets = ['hidden', 'selectedTr'];

  initialize() {}

  connect() {
    this.showFlashMessages()
  }

  beforeFormSubmit(event) {
    let target = event.target;
    // Enable submit button
    let formButton = this.formSubmitButton(target)
    if (formButton) {
      disableOrEnableButton(this.formSubmitButton(target), true)
    }
    // Show Loader
    showOrHideLoader(createLoaderWrapper(target), true)
  }
  afterFormSubmit(event) {
    let target = event.target;
    // Enable submit button
    let formButton = this.formSubmitButton(target)
    if (formButton) {
      disableOrEnableButton(this.formSubmitButton(target), false)
    }
    // Hide Loader
    showOrHideLoader(createLoaderWrapper(target), false)
  }

  formSubmitButton(target) {
    return target.querySelector('button[type="submit"]')
  }

  showFlashMessages() {
    if (typeof flashMessage === 'undefined'){
      return;
    }

    if (flashMessage) {
      showToastr('', flashMessage, 'success')
    }

    if (flashNotice) {
      showToastr('', flashNotice, 'notice')
    }

    if (flashErrors) {
      showToastr('', flashErrors, 'error')
    }
  }

  togglePasswordVisibility(event) {
    let target = event.target;
    var newType = 'password'
    if (this.hiddenTarget.getAttribute('type') === 'text') {
      newType = 'password'
      event.target.classList.add('show-password')
      event.target.classList.remove('hide-password')
    } else {
      newType = 'text'
      event.target.classList.add('hide-password')
      event.target.classList.remove('show-password')
    }
    this.hiddenTarget.setAttribute('type', newType)
  }

  showTdMenu(event) {
    let that = this;
    if (event.type == 'contextmenu' && event.which == 3) {
      event.preventDefault()
    }
    $('tr').removeClass('active-tr')
    $('div.square-checkbox input').removeAttr('checked')
    $('tr td.td-selection').removeAttr('data-target')
    // event.target.setAttribute('data-target', `${this.identifier}.selectedTr`)
    $(event.target).closest('td')[0].setAttribute('data-target', `${that.identifier}.selectedTr`)

    let selectedId = that.selectedTrTarget.getAttribute('data-id')
    let selectedCheckboxTarget = that.selectedTrTarget.getAttribute('data-checkbox-target')
    let selectedStatus = that.selectedTrTarget.getAttribute('data-status')
    let selectedEl = that.selectedTrTarget.getAttribute('data-el')
    let currentEl = selectedCheckboxTarget ? selectedCheckboxTarget : 'squareCheckbox_'

    $('#' + currentEl + selectedId).attr('checked', true)

    positionMenu(event, '.tdMenuPopup', '#tabs');

    if (selectedStatus === 'true') {
      $('.tdMenuPopup .item-show').hide()
      $('.tdMenuPopup .item-hide').show()
    } else {
      $('.tdMenuPopup .item-show').show()
      $('.tdMenuPopup .item-hide').hide()
    }

    this.selectedTrTarget.closest('tr').classList.add('active-tr')

    $('.tdMenuPopup').show();
  }

  hideTdMenu(event) {
    if (event && (event.target.classList.contains('td-selection'))) {
      event.preventDefault();
      return;
    } else {
      $('tr').removeClass('active-tr')
      $('div.square-checkbox input').removeAttr('checked')
      $('.tdMenuPopup').hide();
    }
  }

  redirectTo(event) {
    if (event) {
      let url = event.target.getAttribute('data-url')
      event.preventDefault();
      window.location.replace(url)
    }
  }
}
