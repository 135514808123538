import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['fileName']
  initialize() {}

  connect() {}

  onFileAdd(event) {
    let files = event.target.files;
    let fileName, icon, name;
    let wrapperDiv = document.createElement('div')
    let nameWrapper = document.createElement('span')
    this.fileNameTarget.appendChild(wrapperDiv)
    wrapperDiv.classList.add('file-icon');
    nameWrapper.classList.add('full-width');

    if (files.length == 1) {
      name = document.createElement('span')
      name.innerHTML = files[0].name
      let a = this.fileNameTarget.appendChild(nameWrapper)
      a.appendChild(name)
    } else {
      let node, textnode;
      for (let i = 0; i < files.length; i++) {
        name = document.createElement('span')
        name.innerHTML = files[i].name
        node = document.createElement('SPAN')
        textnode = document.createTextNode(files[i].name)
        node.appendChild(textnode)
        // this.fileNameTarget.appendChild(node)
        node = document.createElement('BR')
        this.fileNameTarget.appendChild(nameWrapper)
        this.fileNameTarget.appendChild(name)
        this.fileNameTarget.appendChild(node)
      }
    }
    name.classList.add('file-name');
  }
}
